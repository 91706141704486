import {Component, OnInit} from '@angular/core';
import { Input } from '@angular/core';
import {AuthenticationService} from '../../login/auth.service';
import { Role } from '../../users/users.model';
@Component({
  selector: 'app-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss'],
})
export class SlotComponent implements OnInit{
  @Input() user;
  @Input() isEmpty;
  @Input() subscribeTime;
  isSlotForCurrentUser = false;
  isManagerOrTeamLead = false;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    const currentUser = this.authenticationService.getCurrentUser();
    if(!this.isEmpty && currentUser?.id === this.user.id) {
      this.isSlotForCurrentUser = true;
    }
    if(this.user.role === Role.Manager || this.user.role === Role.TeamLead) {
      this.isManagerOrTeamLead = true;
    }
  }
}
