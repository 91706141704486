export enum Role {
   Standard = 'Standard',
   TeamLead = 'TeamLead',
   Manager = 'Manager'
}

export interface IUser {
  admin: Boolean;
  hireDate: Date;
  _id?: Number;
  id?: Number; //TODO
  email: String;
  name: String;
  isFirstPassword?: Boolean;
  role: Role;
}
