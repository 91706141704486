<div
  class="slot-container"
  [ngClass]="{ 'slot-container-empty': isEmpty, 'slot-container-current-user': isSlotForCurrentUser }"
>

  <ng-container *ngIf="user.name else emptySlot">
    <div class="slot-container-name">{{user.name}}</div>
    <div class="slot-container-hire-date">{{subscribeTime | date: 'dd MMM HH:mm:ss'}}</div>
  </ng-container>

  <ng-template #emptySlot>

      <img
        src="../assets/img/parking.svg"
        alt="Nexttech" height="40" width="40">

      &lt;empty slot&gt;

  </ng-template>

</div>
