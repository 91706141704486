<div>
  <app-nav-component (notify)="showWeek($event)"></app-nav-component>
  <!-- weekDays will be maped with the result info (allocated ppl) -->
  <div class="days" *ngIf="!showLoading; else showSpinner">
    <app-day-comp [inputData]="day" *ngFor="let day of weekDays"></app-day-comp>
  </div>
</div>
<ng-template #showSpinner>
  <app-spinner diameter="50"></app-spinner>
</ng-template>
