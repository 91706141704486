import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { NtmaterialModule } from '../ntmaterial/ntmaterial.module';

import { UsersComponent } from './users-list.component';
import { UsersService } from './users.service';

import { UpdateUserComponent } from '../user-modifier/update-dialog/dialog-update-user.component';
import { UserCrudBtnComponent } from '../user-modifier/user-crud-button.component';
import { ShowColumnDirective } from './show-column.component';

import { BrowserModule } from '@angular/platform-browser';
import { SpinnerModule } from 'app/shared/components/spinner/spinner.module';
import { DeleteDialogComponent } from '../user-modifier/delete-dialog/delete-dialog.component';
@NgModule({
  declarations: [
    UsersComponent,
    UserCrudBtnComponent,
    UpdateUserComponent,
    ShowColumnDirective,
    DeleteDialogComponent,
  ],
  exports: [UsersComponent, UserCrudBtnComponent, UpdateUserComponent, DeleteDialogComponent],
  entryComponents: [UpdateUserComponent, DeleteDialogComponent],
  imports: [NtmaterialModule, BrowserModule, HttpClientModule, SpinnerModule],
  providers: [UsersService],
})
export class UsersModule {}
