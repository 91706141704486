import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function pswdEquality(passwordField): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!passwordField) return null;
    return control.value === passwordField.value
      ? null
      : { passwordMatch: 'Passwords are not identical' };
  };
}
