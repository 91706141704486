import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  template:
    `<p>
      Are you sure you want to set the user "<span style="font-weight: bolder">{{ data.name }}</span>" as not hired?
    </p>
    <div fxLayout fxLayout="row" fxLayoutAlign="end center">
      <button class="btn" mat-button (click)="onClick('no')">CANCEL</button>
      <button class="btn-primary" mat-button (click)="onClick('yes')">CONFIRM</button>
    </div> `,
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  onClick(selected): void {
    this.dialogRef.close(selected);
  }
}
