import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from './login/auth.service';
import * as moment from "moment/moment";
import {Router} from "@angular/router";
import {MatSidenav} from "@angular/material/sidenav";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy{

  @ViewChild('sidenav')
  sidenav: MatSidenav;
  destroy$ = new Subject<void>();

  currentYear = moment().year();

  constructor(public auth: AuthenticationService, private router: Router) {}

  ngOnInit(): void {
    this.router
      .events
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.sidenav?.close();
    });
  }

  public logout() {
    return this.auth.logout();
  }

  public isLoggedIn() {
    return this.auth.isAuthenticated();
  }

  public shouldResetPassword() {
    return this.auth.shouldResetPassword();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

}
