<div class="wrap">
  <div class="outer">
    <div class="inner">
      <div class="flex-container">
        <div class="row">

          <div class="flex-item">
            <img src="../assets/img/nt-logo-2c-positive.svg"
                 class="logo"
                 alt="Nexttech"
            />
          </div>

          <form (ngSubmit)="resetPassword()">

            <div class="flex-item">
              <mat-form-field>
                <input matInput placeholder="NEW PASSWORD" [formControl]="password" [type]="hide ? 'password' : 'text'" class="sign-in-text">
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                <mat-error *ngIf="password.invalid">You must enter a password</mat-error>
              </mat-form-field>
            </div>
            <div class="flex-item">
              <mat-form-field>
                <input matInput placeholder="NEW PASSWORD CONFIRM" [formControl]="passwordc" [type]="hide ? 'password' : 'text'" class="sign-in-text">
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                <mat-error *ngIf="passwordc.invalid">You must enter a password confirmation</mat-error>
              </mat-form-field>
            </div>
            <div class="flex-item">
              <div class="form-actions">
                <button mat-button color="primary">Reset password</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
