<div class="wrapper">
  <div mat-dialog-title fxLayout="row">
    <i class="material-icons add-icon">add_circle</i>
    <p class="add-text">{{titleText}} USER</p>
  </div>
  <div
    mat-dialog-content
    ngClass="dialog-container-xs"
    ngClass.sm="dialog-container-sm"
    ngClass.gt-sm="dialog-container-gt-sm"
  >
    <form
      [formGroup]="userModifyForm"
      fxLayout="column"
      fxLayout.gt-xs="row"
      fxLayoutAlign="space-between "
      class="example-form"
      novalidate
    >
      <div class="form-column">
        <mat-form-field class="example-full-width">
          <input matInput type="email" formControlName="email" placeholder="Email" />
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput formControlName="name" placeholder="Full Name" />
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input
            matInput
            formControlName="hireDate"
            [matDatepicker]="picker"
            placeholder="Hire Date"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-column">
        <mat-form-field class="example-full-width">
          <input matInput formControlName="password" placeholder="Password" />
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput formControlName="confirmPassword" placeholder="Re-type password" />
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label>Role</mat-label>
          <mat-select [formControl]="role">
            <mat-option *ngFor="let role of roles | keyvalue" [value]="role.value">{{ role.value }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="buttons-wrapper">
      <button mat-button class="btn" color="primary" (click)="onCancel()">CANCEL</button>
      <button
        mat-button
        color="primary"
        class="{{ !userModifyForm.invalid  ? 'btn-primary' : 'btn-disabled'}}"
        type="submit"
        [disabled]="userModifyForm.invalid"
        (click)="postToServer()"
      >
        SUBMIT
      </button>
    </div>
  </div>
</div>
