import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { IUser } from '../users/users.model';

@Injectable()
export class AuthenticationService {
  constructor(private $http: HttpClient, private router: Router) {}

  public currentUser: IUser | null = !!localStorage.currentUser
    ? JSON.parse(localStorage.currentUser)
    : null;

  loginUser(email: string, password: string): Observable<IUser> {
    const loginInfo = { email: email, password: password };

    return this.$http.post<IUser>('authentication/login', loginInfo).pipe(
      tap((response) => {
        if (response) {
          this.currentUser = <IUser>response;
          localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
          localStorage.setItem('jwtToken', response['token']);
        }
      })
    );
  }

  resetPassword(password: string, passwordConfirmation: string): Observable<IUser> {
    return this.$http
      .post<IUser>('users/resetPassword', {
        password: password,
        confirmPassword: passwordConfirmation,
      })
      .pipe(
        tap((response) => {
          if (response) {
            this.currentUser = <IUser>response;
            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
          }
        })
      );
  }

  isAuthenticated() {
    return !!this.currentUser && !!localStorage.getItem('jwtToken');
  }

  shouldResetPassword() {
    return !!this.currentUser && this.currentUser.isFirstPassword;
  }

  getCurrentUser() {
    return this.currentUser;
  }

  isAdmin() {
    return this.isAuthenticated() && this.currentUser?.admin;
  }

  logout() {
    this.currentUser = null;

    localStorage.clear();

    this.router.navigate(['/login']);
  }
}
