<div class="week-nav-wrapper" ngClass.lt-sm="week-nav-wrapper-mobile">

  <div class="nav-container">
    <button
      class="icon-btn"
      matTooltip="Previous Week"
      matTooltipPosition="below"
      (click)="changeWeek(-1)">
      <mat-icon color="primary">keyboard_arrow_left</mat-icon>
    </button>

    <button
      class="icon-btn"
      matTooltip="Next Week"
      matTooltipPosition="below"
      (click)="changeWeek(1)">
      <mat-icon color="primary">keyboard_arrow_right</mat-icon>
    </button>

    <button ngClass.gt-xs="hidden" class="btn" *ngIf="!isCurrentWeek()" (click)="goToCurrentWeek()" mat-button color="primary">
      Current week
    </button>
  </div>

  <div class="time">
    <div>
      <img src="../assets/img/calendar.svg" alt="" height="33">
    </div>

    <div class="time-text">
      <div class="first-row-text">
        Week {{ weekNumber }} / {{ lastDay | date: 'y' }}
      </div>
      <div class="second-row-text">
        {{ firstDay | date: 'MMMM dd' }} - {{ lastDay | date: 'MMMM dd' }}
      </div>
    </div>

  </div>

  <button ngClass.xs="hidden" class="btn" *ngIf="!isCurrentWeek()" (click)="goToCurrentWeek()" mat-button color="primary">
    Current Week
  </button>

</div>


