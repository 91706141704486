import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';

@Injectable()
export class NtHttpInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem('jwtToken');

    // Clone the request to add the new header.
    const clonedRequest = req.clone({
      url: `${environment.baseApi}/${req.url}`,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + idToken,
      }),
    });

    //send the newly created request
    return next.handle(clonedRequest).pipe(
      catchError((err) => {
        if (err.error && err.error.errorMessage) {
          this.openSnackBar(err.error.errorMessage);
        } else if (err.status && err.status === 401) {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.openSnackBar('Your token expired. Please try to login again');
        } else if (err.status && err.status === 403) {
          this.openSnackBar("You don't have sufficient rights to access this route");
        } else if (err.status === 0) {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.openSnackBar('The connection to the server was interrupted');
        } else {
          this.openSnackBar(err.error);
        }

        // return the error to the method that called it
        return of(err);
      })
    ) as any;
  }

  openSnackBar(message: string, action: string = 'Dismiss') {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
