import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';

import {IUser} from './users.model';
import {UsersService} from './users.service';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersComponent implements OnInit, AfterViewInit {

  public usersList: MatTableDataSource<IUser>;
  displayedColumns = ['name', 'hireDate', 'email', 'role', 'edit'];
  errorMessage: String;
  isLoading = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private users: UsersService) {}

  ngOnInit() {
    this.usersList = new MatTableDataSource<IUser>();
    this.users.getUsers().subscribe((user) => {
      this.usersList.data = user;
      this.isLoading = false;
    });
  }

  ngAfterViewInit() {
    this.usersList.sort = this.sort;
    this.usersList.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.usersList.filter = filterValue;
  }

  refreshUser() {
    this.users.getUsers().subscribe((users) => {
      this.usersList.data = users;
    });
  }

}
