import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../users/users.service';
import { Role } from '../../users/users.model';
import { pswdEquality } from '../../shared/validators/pswd-fields.validator';
import { OnInit } from '@angular/core';
@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-update-user.html',
  styleUrls: ['./dialog-update-user.component.scss'],
})
export class UpdateUserComponent implements OnInit {
  userModifyForm: FormGroup;
  hireDate: FormControl;
  email: FormControl;
  name: FormControl;
  password: FormControl;
  confirmPassword: FormControl;
  role: FormControl;
  errors: any;
  public titleText: string;
  roles = Role;

  constructor(
    public dialogRef: MatDialogRef<UpdateUserComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any = {
      admin: '',
      _id: '',
      hireDate: '',
      email: '',
      name: '',
      confirmPassword: '',
      role: '',
    },
    private _usersService: UsersService
  ) {}

  ngOnInit() {
    this.hireDate = new FormControl(this.data.hireDate, Validators.required);

    this.email = new FormControl(this.data.email, [Validators.required, Validators.email]);
    this.name = new FormControl(this.data.name, Validators.required);

    if (!this.isEditMode()) {
      this.titleText = 'ADD';
    } else {
      this.titleText = 'EDIT';
    }

    this.password = new FormControl('');
    this.confirmPassword = new FormControl('', [pswdEquality(this.password)]);

    this.role = new FormControl(this.data.role);

    this.userModifyForm = new FormGroup({
      hireDate: this.hireDate,
      email: this.email,
      name: this.name,
      password: this.password,
      confirmPassword: this.confirmPassword,
      role: this.role,
    });
  }
  postToServer() {
    const user = Object.assign(this.data, this.userModifyForm.value);

    //correcting hire date per timezone offset
    const date = new Date(user.hireDate);

    const offset = date.getTimezoneOffset();
    if (offset < 0) {
      date.setHours(12, 0, 0);
    }

    user.hireDate = date;

    if (!this.isEditMode()) {
      this._usersService.addUser(user).subscribe((response) => this.dialogRef.close(response));
    } else {
      this._usersService.editUser(user).subscribe((response) => this.dialogRef.close(response));
    }
  }
  isEditMode() {
    return this.data.id;
  }
  onCancel() {
    this.dialogRef.close();
  }
}
