import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UpdateUserComponent } from './update-dialog/dialog-update-user.component';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter } from 'rxjs';
import { UsersService } from '../users/users.service';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-user-crud-button',
  template: `
    <div style="display: flex;">
      <button
        class="icon-btn"
        matTooltip="{{ buttonTitle }}"
        (click)="openDialog()">
        <mat-icon color="primary">{{buttonIcon}}</mat-icon>
      </button>
      <button
        *ngIf="btnType === 'edit'"
        style="margin-left: 5px"
        class="icon-btn"
        matTooltip="Delete"
        (click)="doDelete(selectedUser)">
        <mat-icon color="primary">delete</mat-icon>
      </button>
    </div>
  `
})
export class UserCrudBtnComponent implements OnInit {
  @Input() selectedUser;
  @Input() btnType;
  @Output() changedList: EventEmitter<any> = new EventEmitter();

  public buttonTitle: String = 'Edit User';
  public buttonIcon: String = 'mode_edit';
  constructor(
    public dialog: MatDialog,
    private _usersService: UsersService,
    public snackBar: MatSnackBar
  ) {}
  ngOnInit() {
    if (this.btnType === 'add') {
      this.buttonIcon = 'add';
      this.buttonTitle = 'Add User';
    }
  }

  doDelete(selectedUser) {
    this.dialog
      .open(DeleteDialogComponent, { data: this.selectedUser })
      .afterClosed()
      .subscribe((result) => {
        if (result === 'yes') {
          this._usersService.deleteUser(selectedUser.id).subscribe(() => {
            this.changedList.emit(null);
          });
        } else if (result === 'no') {
          return;
        }
      });
  }

  openDialog() {
    const dialogRef = this.dialog.open(UpdateUserComponent, {
      data: this.selectedUser ? this.selectedUser : {},
      minWidth: '30vw',
    });

    dialogRef
      .afterClosed()
      .pipe(filter((rez) => rez))
      .subscribe((result) => {
        this.snackBar.open(
          this.btnType === 'add' ? 'User added succesfully!' : 'User updated succesfully!',
          'Dismiss',
          {
            duration: 5000,
          }
        );
        this.changedList.emit(result);
      });
  }
}
