import {Router, Routes} from '@angular/router';
import {WeekViewComponent} from './week/week-view.component';

import {AuthGuard} from './login/auth-guard.service';
import {LoginComponent} from './login/login.component';
import {ResetPasswordComponent} from './login/reset.component';
import {UsersComponent} from './users/users-list.component';
import {AdminGuard} from './login/admin-guard.service';
import {inject} from "@angular/core";
import {AuthenticationService} from "./login/auth.service";

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [() => {
      if(inject(AuthenticationService).isAuthenticated()) {
        inject(Router).navigate(['/dashboard']);
        return false;
      }
      return true;
    }
    ]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: WeekViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AdminGuard],
  },
  {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
];
