<div class="day-component-wrapper" ngClass.lt-sm="day-component-wrapper-mobile">

  <div class="week-day" ngClass.lt-sm="week-day-mobile">
    <div class="top-info">
      <div class="day-of-month-text">{{ inputDay | date: 'EEEE' }}</div>
      <div class="month">{{ inputDay | date: ' MMMM' }}</div>
    </div>

    <div class="bottom-info" ngClass.sm="day-info-sm" ngClass.xs="day-info-sm" fxLayoutAlign="flexEnd">
      {{ inputDay | date: 'd' }}
    </div>
  </div>

  <div *ngIf="shouldShowLoader()" class="spinner">
    <mat-spinner diameter="40"></mat-spinner>
  </div>

  <div class="parking-slots-controls" ngClass.lt-md="parking-slots-controls-mobile">
    <div
      *ngIf="!shouldShowLoader()"
      class="parking-slots" ngClass.lt-md="parking-slots-mobile"
    >
      <app-slot *ngFor="let allocated of allocatedSorted"
                [user]="allocated.user"
                [isEmpty]="!allocated.user.name"
                [subscribeTime]="allocated.subscribeTime">
      </app-slot>
    </div>

    <div *ngIf="!shouldShowLoader()"
         class="parking-controls"
         ngClass.lt-md="parking-controls-mobile"
         [ngClass]="{'parking-controls-spaced': othersCount, 'parking-controls-bottom': !othersCount}"
    >
      <div class="waiting-list" *ngIf="othersCount">{{ othersCount }} more <br> are waiting</div>

      <div class="park-or-cancel">

        <a class="parking-link"
           [ngClass]="{ 'parking-link-disabled': !isParkingPossible }"
           *ngIf="subscribeBtnStateForParking"
           (click)="subscribeBtnToggle(1)">
          I want to park
        </a>

        <a class="cancel-parking-link"
           [ngClass]="{ 'parking-link-disabled': !isParkingPossible }"
           *ngIf="subscribeBtnStateForCancelParking"
           (click)="subscribeBtnToggle(0)">
          Cancel
        </a>
      </div>
    </div>
  </div>
</div>
