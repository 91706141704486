import { Injectable } from '@angular/core';
import { IUser } from './users.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UsersService {
  constructor(private $http: HttpClient) {}

  getUsers(): Observable<IUser[]> {
    return this.$http.get<IUser[]>('users');
  }

  addUser(newUser: IUser): Observable<any> {
    const postUrl = 'users';
    return this.$http.post<IUser>(postUrl, newUser);
  }

  editUser(user: IUser): Observable<any> {
    const postUrl = `users`;
    return this.$http.put<IUser>(postUrl, user);
  }

  deleteUser(id) {
    const deleteUrl = `users/${id}`;
    return this.$http.put<IUser>(deleteUrl, id);
  }
}
