import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-nav-component',
  templateUrl: './week-nav.component.html',
  styleUrls: ['week-nav.component.scss'],
})
export class WeekNavComponent implements OnInit {
  public weekNumber: Number;
  public selectedDate: Date | null;
  public firstDay: Moment;
  public lastDay: Moment;
  public weekNow: number;

  @Output() notify = new EventEmitter();

  ngOnInit() {
    let initialSelectedDate = moment(new Date());

    const weekday = initialSelectedDate.format('dddd'); // Monday ... Sunday
    const isWeekend = weekday === 'Sunday' || weekday === 'Saturday';
    // Move the current week to the next week if it's Saturday or Sunday
    if (isWeekend) {
      initialSelectedDate = initialSelectedDate.startOf('isoWeek').add(1, 'week');
    }

    this.selectedDate = initialSelectedDate.toDate();
    this.refreshDate(this.selectedDate);

    this.weekNow = moment().isoWeek();
  }

  changeWeek(increment) {
    const selectedWeek = moment(this.selectedDate).isoWeek();
    this.selectedDate = moment(this.selectedDate)
      .isoWeek(selectedWeek + increment)
      .toDate();
    this.refreshDate(this.selectedDate);
  }

  refreshDate(newDate: Date | null) {
    this.weekNumber = moment(newDate).isoWeek();
    this.firstDay = moment(newDate).isoWeekday(1);
    this.lastDay = moment(newDate).isoWeekday(5);

    const weekDaysSelected = [1, 2, 3, 4, 5].map((day) => moment(newDate).isoWeekday(day));

    this.notify.emit({
      weekNumber: this.weekNumber,
      weekDays: weekDaysSelected,
    });
  }

  addEvent(event: MatDatepickerInputEvent<Date>) {
    this.selectedDate = event.value;
    this.refreshDate(event.value);
  }

  goToCurrentWeek() {
    this.selectedDate = new Date();
    this.refreshDate(this.selectedDate);
  }

  isCurrentWeek(): Boolean {
    return this.weekNow === this.weekNumber;
  }
}
