import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-spinner',
  template: `<div class="spinnerContainer">
    <mat-spinner color="primary" [diameter]="diameter"></mat-spinner>
  </div>`,
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() diameter = 100;
}
