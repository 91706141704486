<div class="list-container"
     [ngStyle]="{'display': (isLoading) ? 'none' : 'block'}"
>

  <div class="search">
    <mat-form-field appearance="outline">
      <mat-label>Filter Users</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)"/>
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    <app-user-crud-button
      (changedList)="refreshUser()"
      btnType="add"
    ></app-user-crud-button>
  </div>

  <mat-table
    [dataSource]="usersList"
    matSort
    matSortActive="hireDate"
    matSortDirection="asc"
    matSortDisableClear
  >
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
      <mat-cell *matCellDef="let user">
        <span class="mobile-label">Name:</span>
        {{ user.name }}
      </mat-cell>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Email</mat-header-cell>
      <mat-cell *matCellDef="let user">
        <span class="mobile-label">Email:</span>
        {{ user.email }}
      </mat-cell>
    </ng-container>

    <!-- Hire Date Column -->
    <ng-container matColumnDef="hireDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Hire Date</mat-header-cell>
      <mat-cell *matCellDef="let user">
        <span class="mobile-label">Hire Date:</span>
        {{ user.hireDate | date }}
      </mat-cell>
    </ng-container>

    <!-- Role Column -->
    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Role</mat-header-cell>
      <mat-cell *matCellDef="let user">
        <span class="mobile-label">Role:</span>
        {{ user.role }}
      </mat-cell>
    </ng-container>

    <!-- Edit User Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef>
        Actions
      </mat-header-cell>
      <mat-cell *matCellDef="let user">
        <span class="mobile-label">Actions:</span>
        <app-user-crud-button
          class="edit-button"
          btnType="edit"
          [selectedUser]="user"
          (changedList)="refreshUser()"
        ></app-user-crud-button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <div class="table-footer">
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>

    <app-user-crud-button
      (changedList)="refreshUser()"
      btnType="add"
    ></app-user-crud-button>
  </div>


</div>

<ng-container *ngIf="isLoading">
  <app-spinner diameter="50"></app-spinner>
</ng-container>
